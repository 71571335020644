<template>
  <b-modal
    id="global-market-modal"
    ref="global-market-modal"
    hide-footer
    title="เลือกชนิดหวย"
    @show="resetModal"
    @hidden="resetModal"
    size="xl"
    body-class="p-0"
  >
    <div v-for="group in groups" class="row no-gutters mb-2" v-if="group.markets.length">
      <div class="col-12">
        <div class="px-2 pt-1 pb-0 text-primary">
          <span style="font-size: 110%;">{{ group.groupTitle }}</span>
        </div>
      </div>
      <div v-for="market in group.markets" class="col-md-3">
        <div class="p-1">
          <div class="card d-flex mb-0 flex-row align-items-center m-hover" @click="toggleMarket(market)">
            <img :src="market.imageIcon" style="width: 60px;" class="mr-2">
            <span>{{ market.marketTitle }}</span>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
export default {
  name: 'GlobalMarketModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectGroupId: this.$store.state.globalMarket.groupId
    }
  },
  computed: {
    markets() {
      return this.$store.state?.markets || []
    },
    groups() {
      return this.$store.state.marketGroups.filter((group)=>{
        return group.status === 'Active'
      })
      .map((group)=>{
        group.markets = this.markets.filter((market)=>{
          return group._id === market.groupId && market.status === 'Open'
        })
        return group
      })
    },
    currentGroupId() {
      return this.$store.state.globalMarket.groupId
    },
    currentMarketId() {
      return this.$store.state.globalMarket.marketId
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.selectGroupId = this.$store.state.globalMarket.groupId
        this.showModal()
      }else{
        this.hideModal()
      }
    },
    currentGroupId() {
      this.selectGroupId = this.currentGroupId
    }
  },
  methods: {
    resetModal() {},
    showModal() {
      this.$refs['global-market-modal'].show()
    },
    hideModal() {
      this.$refs['global-market-modal'].hide()
    },
    totalMarketInGroup(groupId) {
      const markets = this.$store.state.markets.filter(market=>market.groupId===groupId)
      return markets.length
    },
    toggleGroupId(groupId) {
      this.selectGroupId = groupId
    },
    toggleMarket(market) {
      this.$store.commit('updateGlobalMarket', {
        groupId: market.groupId,
        marketId: market._id,
        marketType: market.marketType,
        imageIcon: market.imageIcon
      })
      this.$refs['global-market-modal'].hide()
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close')
    })
  }
}
</script>
<style lang="scss">
#global-market-modal {
  .m-hover {
    cursor: pointer;
  }
  .m-hover:hover {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }
}
</style>
