<template>
  <CHeader :fixed="false" with-subheader :light="false">
    <CHeaderNav class="d-md-down-none ml-4 mr-auto">
      <CHeaderNavItem>
        <span class="c-header-nav-link" @click="isShowMarketModal=true">
          <!-- <i class="fas fa-book mr-2"></i> -->
          <img v-if="marketIcon" :src="marketIcon" style="width: 40px; border-radius: 5px;" class="mr-2">
          <!-- <span>{{groupTitle}}</span>
          <i class="fas fa-angle-double-right ml-2 mr-2 text-secondary"></i> -->
          <span>{{marketTitle}}</span>
          <i class="fas fa-caret-down ml-2 text-primary"></i>
        </span>
      </CHeaderNavItem>
      <CHeaderNavItem>
        <div class="c-header-nav-link" @click="isShowRoundModal=true">
          <i class="far fa-calendar-alt mr-1"></i>
          <span class="mr-2">งวดวันที่</span>
          <span class="round-date" v-if="!isLoading">
            <span>{{roundDate}}</span>
            <i class="fas fa-caret-down ml-2 text-primary"></i>
          </span>
          <div v-else class="d-flex">
            <b-spinner small label="Small Spinner" variant="info"></b-spinner>
            <b-spinner small label="Small Spinner" type="grow" class="ml-1" variant="success"></b-spinner>
            <b-spinner small label="Small Spinner" class="ml-1" variant="info"></b-spinner>
            <b-spinner small label="Small Spinner" type="grow" class="ml-1" variant="success"></b-spinner>
            <b-spinner small label="Small Spinner" class="ml-1" variant="info"></b-spinner>
            <b-spinner small label="Small Spinner" type="grow" class="ml-1" variant="success"></b-spinner>
          </div>
        </div>
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav class="mr-4 ml-auto">
      <TheHeaderDropdownAccnt/>
    </CHeaderNav>

    <GlobalMarketModal :isShow="isShowMarketModal" @close="closeMarketModal" />
    <GlobalRoundModal :isShow="isShowRoundModal" @close="closeRoundModal" />
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
import GlobalMarketModal from '@/views/components/GlobalMarketModal'
import GlobalRoundModal from '@/views/components/GlobalRoundModal'

import SystemConfigService from '@/services/SystemConfigService'
import MarketService from '@/services/MarketService'

import moment from '@/helpers/moment'

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt,
    GlobalMarketModal,
    GlobalRoundModal
  },
  data() {
    return {
      isShowMarketModal: false,
      isShowRoundModal: false,
      isLoading: false
    }
  },
  computed: {
    globalMarket() {
      return this.$store.state.globalMarket
    },
    currentGroupId() {
      return this.globalMarket.groupId
    },
    currentMarketId() {
      return this.globalMarket.marketId
    },
    groupTitle() {
      const group = this.$store.state.marketGroups.find(group=>group._id===this.currentGroupId)
      return group?.groupTitle
    },
    marketTitle() {
      const market = this.$store.state.markets.find(market=>market._id===this.currentMarketId)
      return market?.marketTitle
    },
    markets() {
      return this.$store.state.markets
    },
    roundDate() {
      if(!this.globalMarket.round.roundDate)
        return '--/--/----'

      return moment(this.globalMarket.round.roundDate.date).format("DD/MM/YYYY")
    },
    marketIcon() {
      return this.globalMarket?.imageIcon
    }
  },
  watch: {
    currentGroupId() {
      if(!this.currentMarketId) {
        this.setStartMarketId()
      }
    },
    currentMarketId() {
      this.$store.commit('updateGlobalMarket', {
        round: {
          roundId: null,
          roundDate: null
        }
      })
      this.getMarketRound()
    },
    markets(n, o) {
      if(o.length === 0 && !this.currentMarketId) {
        this.setStartMarketId()
      }
    }
  },
  methods: {
    closeMarketModal() {
      this.isShowMarketModal = false
    },
    closeRoundModal() {
      this.isShowRoundModal = false
    },
    setStartMarketId() {
      const marketsInGroup = this.markets.filter((market)=>{
        return market.groupId === this.currentGroupId
      })

      if(marketsInGroup.length) {
        this.$store.commit('updateGlobalMarket', {
          marketId: marketsInGroup[0]._id,
          marketType: marketsInGroup[0].marketType
        })
      }
    },
    getMarketRound() {
      this.isLoading =true
      MarketService.getRound(this.currentMarketId)
      .then((response)=>{
        if(response.success) {
          // console.log(response.data)
          if(response.data) {
            this.$store.commit('updateGlobalMarket', {
              round: {
                roundId: response.data._id,
                roundDate: response.data.roundDate
              }
            })
          }
        }else{
          throw new Error(response?.message)
        }
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
  }
}
</script>
<style lang="scss">
.c-header-nav-link {
  cursor: pointer;
  span {
    font-size: 100%;
    color: #111;

    span {
      font-size: 100%;
    }
  }
}
</style>
